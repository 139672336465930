import React, { useMemo, useState, useCallback, useRef, ChangeEventHandler } from 'react';
import * as Yup from 'yup';
import objectPath from 'object-path';
import classNames from 'classnames';
import delay from 'lodash/delay';
import { FormikTextInput } from 'src/common/form/formik/text-input';
import { JourneyForm, JourneyFormik } from '../common/JourneyFormik';
import { VideoAsset } from '../common/helpers/video_asset';
import { VideojsPlayer } from 'src/common/VideojsPlayer';
import { JourneyRequestAccessContent } from './JourneyRequestAccessContent';
import { useDeviceLayout } from 'src/utils/element/use-device-layout.hook';
import { getBrandingBackgroundColor } from 'src/utils/branding';
import { Creator } from 'src/common/interfaces/creator.interface';
import { functionNoop } from 'src/utils/function/noop';
import JourneyPlayerPersonalizationContainer from './personalization/container';
import { EmbedPlayerNdaGating } from 'src/dashboard/embed/nda-gating';
import { PlayerNavigationJourneyUpsellButton } from './navigation/journey-upsell-button';
import { Button } from 'src/common/button';
import { Journey } from 'src/common/interfaces/journey.interface';
import { Nullable } from 'src/types/nullable.type';
import { ErrorInterface } from 'src/common/interfaces/error.interface';
import { hasJourneyUpsellNode } from 'src/utils/journey';
import { AliasCustomFieldType } from 'src/common/interfaces/journey/alias.interface';

export interface JourneyEmailGatingProps {
  journey: Journey;
  email?: string | null;
  password?: string;
  emailRequired?: boolean;
  passwordRequired?: boolean;
  accessCodeRequired?: boolean;
  emailVerificationRequired?: boolean;
  ndaSignatureRequired?: boolean;
  customFields: AliasCustomFieldType[];
  introVideoAsset?: VideoAsset | null;
  onSubmit?: (values: any) => Promise<any>;
  onSuccess?: (values: any) => Promise<any>;
  onCodeExpired?: () => void;
  onBypassClick: (email: string) => void;
  onResendClick?: (email: string) => void;
  onNdaSigned?: (email: string, signature: string, customFields?: { fullName: string; companyName: string }) => void;
}

const RESEND_ACCESS_CODE_TIMEOUT_DURATION = 3000;

const containerClass = 'flex flex-col text-bedrock-black space-y-8 w-full md:space-y-2';
const formClass = 'relative flex flex-col space-y-4 text-left md:w-80 pt-6 pt-0 md:pt-6 md:mt-0';

export const JourneyEmailGating = ({
  journey,
  email: initialEmail,
  password,
  emailRequired,
  passwordRequired,
  accessCodeRequired,
  emailVerificationRequired,
  ndaSignatureRequired,
  customFields,
  introVideoAsset,
  onSubmit,
  onResendClick = functionNoop,
  onBypassClick = functionNoop,
  onCodeExpired,
  onNdaSigned = functionNoop,
}: JourneyEmailGatingProps) => {
  const errorTypeRef = useRef<'password' | 'email' | 'phone_number' | ''>('');
  const [enableResend, setEnableResend] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const [isCodeExpired, setIsCodeExpired] = useState<boolean>(false);
  const [showRequestAccess, setShowRequestAccess] = useState<boolean>(false);
  const [email, setEmail] = useState(initialEmail);
  const { isMobileLayout } = useDeviceLayout();
  const [formSubmission, setFormSubmission] = useState<any>({});

  const shouldShowEmailGateCopy = passwordRequired || emailRequired || emailVerificationRequired;

  const emailGateCopy = useMemo(() => {
    if (accessCodeRequired) {
      return 'Access code emailed';
    }

    if (passwordRequired && emailRequired) {
      return 'Protected Journey';
    } else if (passwordRequired && !emailRequired) {
      return 'Password protected';
    }

    if (emailRequired || emailVerificationRequired) {
      return 'Email verification required';
    }
  }, [accessCodeRequired, emailRequired, emailVerificationRequired, passwordRequired]);

  const emailGateSubCopy = useMemo(() => {
    const orgName = journey.organization.name;

    if (accessCodeRequired) {
      return `Check your inbox - either copy the access code or tap the magic link to view this Journey.`;
    }

    const sharedEmailInfo = `Your email address will only be shared with ${orgName}.`;

    if (passwordRequired && emailRequired) {
      return `This Journey requires an email and password to view. ${sharedEmailInfo}`;
    } else if (passwordRequired && !emailRequired) {
      return null;
    }

    if (emailVerificationRequired) {
      return `This Journey requires you to verify your email address. Enter it below to receive an access code and magic link. ${sharedEmailInfo}`;
    } else if (emailRequired) {
      return sharedEmailInfo;
    }
  }, [accessCodeRequired, emailRequired, emailVerificationRequired, journey.organization.name, passwordRequired]);

  const customFieldsValidationsSchema = useMemo(() => {
    const schema: any = {};

    customFields.forEach((ct) => {
      schema[ct.field] = Yup.string().required('Required');
    });

    return schema;
  }, [customFields]);

  const customFieldsInitialValues = useMemo(() => {
    const initialValues: any = {};

    customFields.forEach((ct) => {
      initialValues[ct.field] = '';
    });

    return initialValues;
  }, [customFields]);

  const formikParams = useMemo(
    () => ({
      initialValues: {
        email: email || '',
        password: password || '',
        access_code: '',
        ...customFieldsInitialValues,
      },
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit: (values: any) => {
        if (onSubmit) {
          setFormSubmission(values);
          errorTypeRef.current = '';
          setEnableResend(false);
          return onSubmit(values)
            .then(() => {
              delay(() => {
                setEnableResend(true);
              }, RESEND_ACCESS_CODE_TIMEOUT_DURATION);
              setIsCodeExpired(false);
              setErrorMessage(null);
              setEmail(values.email);
            })
            .catch((e: object) => {
              const errorType: string = objectPath.get(e, 'error_type', 'UnauthorizedError');
              const { message: originalErrorMessage } = e as ErrorInterface;
              //@ts-ignore
              const message = originalErrorMessage.toLowerCase();
              if (message.includes('password')) {
                errorTypeRef.current = 'password';
              } else if (message.includes('email address')) {
                errorTypeRef.current = 'email';
              }
              if (errorType === 'VerificationExpiredError') {
                setIsCodeExpired(true);
                if (onCodeExpired) {
                  onCodeExpired();
                }
              } else {
                setErrorMessage(originalErrorMessage);
              }
              const can_request_access: boolean = objectPath.get(e, 'data.can_request_access', false);
              if (can_request_access) {
                setEmail(values.email);
                setShowRequestAccess(true);
              } else {
                setErrorMessage(objectPath.get(e, 'message', 'Invalid credentials'));
              }
              setEnableResend(true);
            });
        }
      },
      validationSchema: Yup.object({
        ...(emailRequired && { email: Yup.string().email('Invalid email address').required('Required') }),
        ...(passwordRequired && { password: Yup.string().required('Required') }),
        ...(accessCodeRequired && { access_code: Yup.string().required('Required') }),
        ...customFieldsValidationsSchema,
      }),
    }),
    [
      accessCodeRequired,
      email,
      emailRequired,
      onCodeExpired,
      onSubmit,
      password,
      passwordRequired,
      customFieldsValidationsSchema,
      customFieldsInitialValues,
    ]
  );

  const maybeRenderCustomFields = () => {
    if (!customFields || customFields.length === 0) {
      return null;
    }

    return customFields.map((cf: AliasCustomFieldType) => {
      if (cf.field === 'phone_number') {
        const formatPhoneNumber = (value: string) => {
          const cleaned = ('' + value).replace(/[^\d+]/g, '');

          if (!cleaned) {
            return '';
          }

          const countryCodeMatch = cleaned.match(/^\+\d{1,3}/);
          const countryCode = countryCodeMatch ? countryCodeMatch[0] : '';
          const phoneNumber = cleaned.slice(countryCode.length);

          let formattedNumber = '';
          if (phoneNumber.length > 0) {
            formattedNumber += `(${phoneNumber.slice(0, 3)})`;
          }
          if (phoneNumber.length > 3) {
            formattedNumber += ` ${phoneNumber.slice(3, 6)}`;
          }
          if (phoneNumber.length > 6) {
            formattedNumber += `-${phoneNumber.slice(6, 10)}`;
          }

          return countryCode + ' ' + formattedNumber.trim();
        };

        return (
          <FormikTextInput
            id={cf.field}
            name={cf.field}
            type='text'
            useCustomOnChange
            customOnChange={(onChangeFn: ChangeEventHandler<HTMLInputElement>) => {
              return (e: React.ChangeEvent<HTMLInputElement>) => {
                if (errorMessage) {
                  setErrorMessage(null);
                }
                const { value } = e.target;
                const formattedNumber = formatPhoneNumber(value);
                e.target.value = formattedNumber;
                onChangeFn(e);
              };
            }}
            errorMessage={errorTypeRef.current === cf.field ? errorMessage : ''}
            required
            placeholder={cf.label}
          />
        );
      }
      return (
        <FormikTextInput
          id={cf.field}
          name={cf.field}
          type='text'
          onChange={() => {
            if (errorMessage) {
              setErrorMessage(null);
            }
          }}
          errorMessage={errorTypeRef.current === cf.field ? errorMessage : ''}
          required
          placeholder={cf.label}
        />
      );
    });
  };

  const accessGateContent = useMemo(() => {
    const { hex } = getBrandingBackgroundColor(journey);

    const onBypassClicked = () => {
      const { email } = journey.creator as Creator;
      if (email) {
        onBypassClick(email);
      }
    };

    return (
      <div className={containerClass}>
        {shouldShowEmailGateCopy && (
          <>
            <div className='text-bedrock-serif-h2 md:text-bedrock-serif-h1'>{emailGateCopy}</div>
            {emailGateSubCopy && (
              <div className='text-left !mt-2 md:!mt-4 text-bedrock-p text-bedrock-black max-w-none md:max-w-[37.5rem]'>
                {emailGateSubCopy}
                {accessCodeRequired && (
                  <>
                    <br />
                    <span
                      onClick={() => {
                        if (email) {
                          onResendClick(email);
                          setEnableResend(false);
                          delay(() => {
                            setEnableResend(true);
                          }, RESEND_ACCESS_CODE_TIMEOUT_DURATION);
                        }
                      }}
                      className={classNames('underline', {
                        'opacity-80 pointer-events-none cursor-default': !enableResend,
                        'cursor-pointer': enableResend,
                      })}
                    >
                      Resend email if you haven’t received it.
                    </span>
                  </>
                )}
              </div>
            )}
          </>
        )}
        {!!introVideoAsset && <VideojsPlayer brandingColor={hex} videoAsset={introVideoAsset} />}
        <JourneyFormik {...formikParams}>
          <JourneyForm className={formClass}>
            {emailRequired && !accessCodeRequired && (
              <>
                <FormikTextInput
                  id='gate-email'
                  name='email'
                  type='text'
                  errorMessage={errorTypeRef.current === 'email' ? errorMessage : ''}
                  onChange={() => {
                    if (errorMessage) {
                      setErrorMessage(null);
                    }
                  }}
                  required
                  placeholder='Email address'
                  autoFocus
                />
                {maybeRenderCustomFields()}
              </>
            )}
            {passwordRequired && !accessCodeRequired && (
              <FormikTextInput
                id='gate-password'
                required
                errorMessage={errorTypeRef.current === 'password' ? errorMessage : ''}
                onChange={() => {
                  if (errorMessage) {
                    setErrorMessage(null);
                  }
                }}
                name='password'
                type='text'
                autoFocus={!emailRequired}
                placeholder='Enter password'
              />
            )}
            {accessCodeRequired && (
              <FormikTextInput
                id='gate-access-code'
                autoFocus
                name='access_code'
                type='text'
                errorMessage={errorMessage}
                onChange={() => {
                  if (errorMessage) {
                    setErrorMessage(null);
                  }
                }}
                required
                placeholder='Access code'
              />
            )}
            <Button
              size='regular'
              variant='primary'
              className='w-full'
              label={isCodeExpired ? 'Request new access code' : 'View Journey'}
              type='submit'
            />
            {journey.can_edit && (
              <div className='flex flex-col !mt-8 space-y-8'>
                <div className='h-px bg-bedrock-gray-medium w-full rounded-[100px]' />
                <div className='flex items-center space-x-3 text-bedrock-black'>
                  <Button variant='secondary' size='regular' onClick={onBypassClicked} label='Bypass' />
                  <span className='text-bedrock-p-small'>As the creator, you can bypass this</span>
                </div>
              </div>
            )}
          </JourneyForm>
        </JourneyFormik>
      </div>
    );
  }, [
    accessCodeRequired,
    email,
    emailGateCopy,
    emailGateSubCopy,
    emailRequired,
    enableResend,
    errorMessage,
    formikParams,
    introVideoAsset,
    isCodeExpired,
    journey,
    onBypassClick,
    onResendClick,
    passwordRequired,
    customFields,
    shouldShowEmailGateCopy,
  ]);

  const renderContainer = useCallback(
    (children) => (
      <div className='flex flex-col h-screen'>
        <div className='flex w-full px-4 md:px-6 py-6 items-start'>
          <JourneyPlayerPersonalizationContainer journey={journey} logoClasses='h-6' />
        </div>
        <div className='flex-1 flex-col pb-6 md:grid-cols-12 md:grid md:items-start'>
          <div className='relative h-full flex flex-col w-full p-4 md:p-0 mx-auto md:mx-0 col-start-3 col-span-6 items-center md:items-start md:justify-center'>
            {children}
          </div>
        </div>
        {!isMobileLayout && hasJourneyUpsellNode(journey) && (
          <div className='flex w-full p-6'>
            <PlayerNavigationJourneyUpsellButton />
          </div>
        )}
      </div>
    ),
    [isMobileLayout, journey]
  );

  const accessGate = useMemo(() => {
    let ContentElement = accessGateContent;
    if (ndaSignatureRequired && email) {
      ContentElement = (
        <EmbedPlayerNdaGating
          email={email}
          onSignNdaClick={onNdaSigned}
          customNdaTemplateUrl={journey.alias.custom_nda_template_url}
          customFields={{ fullName: formSubmission.full_name, companyName: formSubmission.company_name }}
        />
      );
    } else if (showRequestAccess) {
      ContentElement = <JourneyRequestAccessContent journey={journey} initialEmail={email} />;
    }

    return renderContainer(ContentElement);
  }, [email, journey, ndaSignatureRequired, onNdaSigned, renderContainer, accessGateContent, showRequestAccess]);

  return accessGate;
};
