import React, { useMemo } from 'react';
import objectPath from 'object-path';

import journeyLogo from 'src/images/journey-logo-new.svg';
import classNames from 'classnames';

const JOURNEY_IO_URL = 'https://journey.io';

const LogoLinkContent = ({ src, className }) => <img alt='Logo' className={className} src={src} />;
const TextLinkContent = ({ text }) => <h1 className='text-2xl text-black capitalize'>{text}</h1>;

function formatURL(url) {
  if (!url.startsWith('http://') && !url.startsWith('https://') && !url.startsWith('ftp://')) {
    return `https://${url}`;
  } else {
    return url;
  }
}

function JourneyPlayerLogo(props) {
  const { journey, linkClasses, logoClasses = 'h-8 w-auto' } = props;
  const organizationName = objectPath.get(journey, 'organization.name', '');
  const brandingLogoUrl = objectPath.get(journey, 'branding.logo_url', '');

  const destinationUrl = useMemo(() => {
    let url = new URL(formatURL(objectPath.get(journey, 'organization.url', '') || JOURNEY_IO_URL));
    if (url.host === 'journey.io') {
      url.searchParams.append('utm_source', 'journey');
      url.searchParams.append('utm_medium', 'header_logo');
      url.searchParams.append('utm_campaign', objectPath.get(journey, 'organization.slug', ''));
    }

    return url.href;
  }, [journey]);

  let linkContent;
  if (brandingLogoUrl) {
    linkContent = (
      <LogoLinkContent
        src={brandingLogoUrl}
        className={classNames(logoClasses, {
          // Covering the case where the logo is an SVG and they might not have set the height/width at the root
          // No way to know if they do. So we'll just set full height and width for all SVG logos
          '!h-full !w-full': brandingLogoUrl.endsWith('.svg'),
        })}
      />
    );
  } else if (organizationName) {
    linkContent = <TextLinkContent text={organizationName} />;
  } else {
    linkContent = <LogoLinkContent src={journeyLogo} className={classNames(logoClasses)} />;
  }

  return (
    <a href={destinationUrl} target='_blank' rel='noopener noreferrer' className={classNames(linkClasses)}>
      {linkContent}
    </a>
  );
}

export default JourneyPlayerLogo;
